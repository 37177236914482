import type {FC} from 'react';
import clsx from 'clsx';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Link} from '@/modules/foundation/components/link';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';

import type {Person} from '../../shared/types';

export interface PersonCardProps {
	person: Person;
}

export const PersonCard: FC<PersonCardProps> = ({person}) => {
	const {name, image, phone, email, role} = person;

	return (
		<div className={clsx('relative', 'grid', 'group', 'gap-1')}>
			<div className={clsx('rounded-xl', 'overflow-hidden', 'mb-4')}>
				<ImageBlock
					alt={image.altText ?? ''}
					height={image.image.height}
					width={image.image.width}
					src={image.image.src}
					className={clsx(
						'group-hover:scale-110',
						'group-hover:-rotate-2',
						'transition-transform',
						'duration-300',
					)}
				/>
			</div>

			<Paragraph
				size="xlarge"
				className={clsx('font-display', 'text-[#290076]', 'font-medium', 'mb-2')}
			>
				{name}
			</Paragraph>

			{role && <Paragraph className={clsx('italic')}>{role}</Paragraph>}

			{phone && <Link href={createPhoneLink(phone)}>{formatPhoneNumber(phone)}</Link>}

			{email && <Link href={`mailto:${email}`}>{email}</Link>}
		</div>
	);
};
