import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Category} from '../components/article-list-paginated/filter';
import type {Article} from '../shared/types';

import {DOCUMENT_TYPE_ARTICLE} from './constants';
import type {ArticleSchema} from './schema';

export const ARTICLE_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	_createdAt,
	image,
	lead,
	categories[]-> {
		_id,
		title,
		"slug": slug.current,
	}
`;

export type ArticleInCardFragment = Pick<
	ArticleSchema,
	'_id' | 'title' | 'slug' | '_createdAt' | 'image' | 'lead'
> & {
	categories?: KeyedArray<Category>;
};

export function articleInCardFragmentToArticleDetails(article: ArticleInCardFragment): Article {
	return {
		title: article.title ?? '',
		createdAt: article._createdAt ?? '',
		href: internalLinkToHref(DOCUMENT_TYPE_ARTICLE, article.slug?.current),
		imageWeb: article?.image?.asset
			? imageWebFromImageWebSchema(article.image, 4 / 3)
			: undefined,
		categories: article.categories,
		lead: article.lead ? toPlainText(article.lead) : '',
	};
}
