import clsx from 'clsx';

type Props = {
	checked: boolean;
	id: string;
	label: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
};

export const FilterChip = ({id, checked, onChange, value, label}: Props) => {
	return (
		<label
			htmlFor={id}
			className={clsx(
				'relative',
				'inline-flex',
				'justify-center',
				'items-center',
				'whitespace-nowrap',
				'px-4',
				'pb-1',
				'pt-2',
				'rounded-full',
				'focus-within:outline-3',
				'focus-within:outline-blue-700',
				'focus-within:outline',
				'focus-within:outline-offset-2',
				'border',
				'border-primary',
				`${!checked ? 'bg-white' : 'bg-primary text-white'}`,
			)}
		>
			<input
				aria-labelledby={`${id}-label`}
				type="radio"
				name="list-filter"
				onChange={onChange}
				id={id}
				value={value}
				checked={checked}
				className={clsx(
					'absolute',
					'inset-0',
					'w-full',
					'h-full',
					'opacity-0',
					'appearance-none',
					'cursor-pointer',
				)}
			/>
			<span id={`${id}-label`}>{label}</span>
		</label>
	);
};
