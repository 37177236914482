import type {FC} from 'react';

import ArticleList from '../../components/article-list';

import {
	articleListFromArticleListSelectionSection,
	type ArticleListSelectionFragmentType,
} from './query';

interface Props {
	data: ArticleListSelectionFragmentType;
}

export const ArticleListSelectionContainer: FC<Props> = ({data}) => {
	const articles = articleListFromArticleListSelectionSection(data);

	return (
		<ArticleList
			title={data.title}
			tagline={data.tagline}
			articles={articles}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			backgroundColor={data.backgroundColor}
		/>
	);
};
