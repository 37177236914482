import type {FC} from 'react';
import clsx from 'clsx';

import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceCategoryDetails} from '../../shared/types';
import {ServiceCategoryCard} from '../service-category-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	serviceCategories: KeyedArray<ServiceCategoryDetails>;
	backgroundColor?: ContainerBackgroundColor;
	heading?: string;
	tagline?: string;
}

export const ServiceCategoryList: FC<Props> = ({
	spacingBottom,
	spacingTop,
	serviceCategories,
	backgroundColor,
	heading,
	tagline,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{(heading || tagline) && (
				<div className={clsx('mb-8')}>
					{heading && (
						<Heading level={2} spacing>
							{heading}
						</Heading>
					)}
					{tagline && <Paragraph>{tagline}</Paragraph>}
				</div>
			)}

			<ul className={clsx('grid', 'sm:grid-cols-2', 'md:grid-cols-3', 'gap-8')}>
				{serviceCategories?.map((serviceCategory) => (
					<li key={serviceCategory._key}>
						<ServiceCategoryCard
							title={serviceCategory.title}
							description={serviceCategory.description}
							href={serviceCategory.href}
							services={serviceCategory.services}
						/>
					</li>
				))}
			</ul>
		</Container>
	);
};
