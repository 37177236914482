import type {FC} from 'react';
import React from 'react';
import clsx from 'clsx';

import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

import {Form} from '../form';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerBackgroundColor;
	heading?: string;
	tagline?: string;
}

export const ContactFormSection: FC<Props> = ({
	spacingBottom,
	spacingTop,
	backgroundColor,
	heading,
	tagline,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{(heading || tagline) && (
				<div className={clsx('text-center', 'mb-20')}>
					{heading && (
						<Heading level={2} spacing className="text-balance">
							{heading}
						</Heading>
					)}
					{tagline && <Paragraph className="text-balance">{tagline}</Paragraph>}
				</div>
			)}
			<Form />
		</Container>
	);
};
