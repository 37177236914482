import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerCaseDetails} from '../../shared/types';
import {DOCUMENT_TYPE_CUSTOMER_CASE} from '../customer-case/constants';
import type {CustomerCaseInCardFragment} from '../customer-case/query';
import {
	CUSTOMER_CASE_IN_CARD_FRAGMENT,
	customerCaseInCardFragmentToCustomerCaseDetails,
} from '../customer-case/query';

import type {BLOCK_TYPE_CUSTOMER_CASE_LIST_ALL} from './constants';
import type {CustomerCaseListAllSchema} from './schema';

export const CUSTOMER_CASE_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"customerCases": *[_type == "${DOCUMENT_TYPE_CUSTOMER_CASE}"] | order(_createdAt desc) {
			${CUSTOMER_CASE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"customerCases": *[_type == "${DOCUMENT_TYPE_CUSTOMER_CASE}"] | order(_createdAt asc) {
			${CUSTOMER_CASE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"customerCases": *[_type == "${DOCUMENT_TYPE_CUSTOMER_CASE}"] | order(lower(title) asc) {
			${CUSTOMER_CASE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"customerCases": *[_type == "${DOCUMENT_TYPE_CUSTOMER_CASE}"] | order(lower(title) desc) {
			${CUSTOMER_CASE_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"customerCases": *[_type == "${DOCUMENT_TYPE_CUSTOMER_CASE}"] | order(_createdAt desc) {
			${CUSTOMER_CASE_IN_CARD_FRAGMENT}
		}
	}
`;

export type CustomerCaseListAllFragment = CustomerCaseListAllSchema & {
	_type: typeof BLOCK_TYPE_CUSTOMER_CASE_LIST_ALL;
	customerCases: CustomerCaseInCardFragment[];
};

export const customerCaseListFromCustomerCaseListAllSection = (
	section: CustomerCaseListAllFragment,
): KeyedArray<CustomerCaseDetails> => {
	const customerCases: KeyedArray<CustomerCaseDetails> = [];

	if (!section.customerCases?.length) {
		return customerCases;
	}

	for (const customerInCard of section.customerCases) {
		if (customerInCard) {
			const customerCase = customerCaseInCardFragmentToCustomerCaseDetails(customerInCard);
			if (customerCase) {
				customerCases.push({
					_key: customerInCard._id,
					...customerCase,
				});
			}
		}
	}

	return customerCases;
};
