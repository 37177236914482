import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';

import type {CustomerTestimonialDetails} from '../../shared/types';

import type {CustomerTestimonialSchema} from './schema';

export const CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT = groq`
	_id,
	name,
	image,
	quote,
	role,
	customer-> {
		title,
	},
`;

export type CustomerTestimonialInCardFragment = Pick<
	CustomerTestimonialSchema,
	'_id' | 'name' | 'quote' | 'image' | 'role'
> & {
	customer?: {
		title?: string;
	};
};

export function customerTestimonialInCardFragmentToCustomerTestimonialDetails(
	customerTestimonial: CustomerTestimonialInCardFragment,
): CustomerTestimonialDetails {
	return {
		name: customerTestimonial.name,
		imageWeb: customerTestimonial?.image?.asset
			? imageWebFromImageWebSchema(customerTestimonial.image, 1)
			: undefined,
		quote: customerTestimonial.quote,
		role: customerTestimonial.role,
		customer: customerTestimonial.customer?.title,
	};
}
