import type {FC} from 'react';
import clsx from 'clsx';

import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerTestimonialDetails} from '../../shared/types';
import {CustomerTestimonialCard} from '../customer-testimonial-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	customerTestimonials: KeyedArray<CustomerTestimonialDetails>;
	backgroundColor?: ContainerBackgroundColor;
	heading?: string;
	tagline?: string;
}

export const CustomerTestimonialList: FC<Props> = ({
	spacingBottom,
	spacingTop,
	customerTestimonials,
	backgroundColor,
	heading,
	tagline,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{(heading || tagline) && (
				<div className={clsx('text-center', 'mb-8')}>
					{heading && (
						<Heading level={2} spacing>
							{heading}
						</Heading>
					)}
					{tagline && <Paragraph>{tagline}</Paragraph>}
				</div>
			)}

			<ul className={clsx('grid', 'gap-16')}>
				{customerTestimonials && (
					<>
						{customerTestimonials.map((customerTestimonials) => {
							return (
								<li key={customerTestimonials._key}>
									<CustomerTestimonialCard
										name={customerTestimonials.name}
										imageWeb={customerTestimonials.imageWeb}
										quote={customerTestimonials.quote}
										role={customerTestimonials.role}
										customer={customerTestimonials.customer}
									/>
								</li>
							);
						})}
					</>
				)}
			</ul>
		</Container>
	);
};
