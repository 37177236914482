import type {FC} from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import {Button} from '@/modules/foundation/components/button';
import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerCaseDetails} from '../../shared/types';
import {CustomerCaseCard} from '../customer-case-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	customerCases: KeyedArray<CustomerCaseDetails>;
	backgroundColor?: ContainerBackgroundColor;
	heading?: string;
	tagline?: string;
	showMoreLink?: boolean;
}

export const CustomerCaseList: FC<Props> = ({
	spacingBottom,
	spacingTop,
	customerCases,
	backgroundColor,
	heading,
	tagline,
	showMoreLink,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{(heading || tagline) && (
				<div className={clsx('mb-8')}>
					{heading && (
						<Heading level={2} spacing>
							{heading}
						</Heading>
					)}
					{tagline && <Paragraph>{tagline}</Paragraph>}
				</div>
			)}

			<ul className={clsx('grid', 'sm:grid-cols-2', 'md:grid-cols-3', 'gap-8')}>
				{customerCases?.map((customerCase) => (
					<li key={customerCase._key}>
						<CustomerCaseCard
							heading={customerCase.heading}
							imageWeb={customerCase.imageWeb}
							introduction={customerCase.introduction}
							href={customerCase.href}
							customer={customerCase.customer}
						/>
					</li>
				))}
			</ul>

			{showMoreLink && (
				<Button asChild className={clsx('mt-8', 'justify-self-start')}>
					<Link href={internalLinkToHref('customerCaseLandingPage')}>
						Se flere kundecaser
					</Link>
				</Button>
			)}
		</Container>
	);
};
