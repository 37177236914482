import type {ZodType} from 'zod';
import {z} from 'zod'; // Add new import

import type {FormData} from '.';

export const FormSchema: ZodType<FormData> = z.object({
	name: z.string().min(1, {
		message: 'Navn må fylles ut',
	}),
	email: z.string().email({
		message: 'Må være en gyldig e-postadresse',
	}),
	phone: z.string().optional(),
	businessName: z.string().optional(),
	message: z.string().optional(),
});
