import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceCategoryDetails} from '../../shared/types';
import type {ServiceCategoryInCardFragment} from '../service-category/query';
import {
	SERVICE_CATEGORY_IN_CARD_FRAGMENT,
	serviceCategoryInCardFragmentToServiceCategoryDetails,
} from '../service-category/query';

import type {BLOCK_TYPE_SERVICE_CATEGORY_LIST_SELECTION} from './constants';
import type {ServiceCategoryListSelectionSchema} from './schema';

export const SERVICE_CATEGORY_LIST_SELECTION_FRAGMENT = groq`
	...,
	serviceCategories[]-> {
		${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
	}
`;

export type ServiceCategoryListSelectionFragment = Omit<
	ServiceCategoryListSelectionSchema,
	'serviceCategories'
> & {
	_type: typeof BLOCK_TYPE_SERVICE_CATEGORY_LIST_SELECTION;
	serviceCategories?: (ServiceCategoryInCardFragment | null)[];
};

export const serviceCategoryListFromServiceCategoryListSelectionSection = (
	section: ServiceCategoryListSelectionFragment,
): KeyedArray<ServiceCategoryDetails> => {
	const serviceCategories: KeyedArray<ServiceCategoryDetails> = [];

	if (!section.serviceCategories) {
		return serviceCategories;
	}

	for (const serviceCategoryInCard of section.serviceCategories) {
		if (serviceCategoryInCard) {
			const serviceCategory =
				serviceCategoryInCardFragmentToServiceCategoryDetails(serviceCategoryInCard);
			if (serviceCategory) {
				serviceCategories.push({
					_key: serviceCategoryInCard._id,
					...serviceCategory,
				});
			}
		}
	}

	return serviceCategories;
};
