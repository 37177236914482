import type {FC} from 'react';

import {CustomerCaseList} from '../../components/customer-case-list';

import type {CustomerCaseListSelectionFragment} from './query';
import {customerCaseListFromCustomerCaseListSelectionSection} from './query';

type Props = {
	data: CustomerCaseListSelectionFragment;
};

export const CustomerCaseListSelectionContainer: FC<Props> = ({data}) => {
	const customerCases = customerCaseListFromCustomerCaseListSelectionSection(data);

	return (
		<CustomerCaseList
			customerCases={customerCases}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			tagline={data.tagline}
			showMoreLink={data.showMoreLink}
		/>
	);
};
