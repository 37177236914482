import type {FC} from 'react';

import PersonList from '../../components/person-list/PersonList';

import {type PersonListAllFragment, personListFromPersonListAllSection} from './query';

type Props = {
	data: PersonListAllFragment;
};

export const PersonListAllContainer: FC<Props> = ({data}) => {
	return (
		<PersonList
			people={personListFromPersonListAllSection(data)}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			backgroundColor={data.backgroundColor}
			tagline={data.tagline}
			title={data.title}
		/>
	);
};
