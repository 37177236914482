import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';

import type {CustomerCaseDetails} from '../../shared/types';

import {DOCUMENT_TYPE_CUSTOMER_CASE} from './constants';
import type {CustomerCaseSchema} from './schema';

export const CUSTOMER_CASE_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	_createdAt,
	image,
	layout,
	introduction,
	customer-> {
		title
	}
`;

export type CustomerCaseInCardFragment = Pick<
	CustomerCaseSchema,
	'_id' | 'title' | 'slug' | 'image' | 'introduction'
> & {
	customer?: {
		title?: string;
	};
};

export function customerCaseInCardFragmentToCustomerCaseDetails(
	customerCase: CustomerCaseInCardFragment,
): CustomerCaseDetails {
	return {
		heading: customerCase.title,
		href: internalLinkToHref(DOCUMENT_TYPE_CUSTOMER_CASE, customerCase.slug?.current),
		imageWeb: customerCase?.image?.asset
			? imageWebFromImageWebSchema(customerCase.image, 16 / 9)
			: undefined,
		introduction: customerCase.introduction ? toPlainText(customerCase.introduction) : '',
		customer: customerCase.customer?.title,
	};
}
