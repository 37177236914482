import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {
	Container,
	type ContainerBackgroundColor,
	type ContainerSpacing,
} from '@/modules/foundation/components/container';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren<{
	heading?: string;
	image?: ImageWeb;
	imageAlignment?: 'left' | 'right';
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerBackgroundColor;
}>;

export const textWithImageSplitPortableTextOverrides: PortableTextOverrides = {};

export const TextWithImageSplit: FC<Props> = ({
	heading,
	image,
	imageAlignment,
	spacingTop,
	spacingBottom,
	children,
	backgroundColor,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			<div
				className={clsx('grid', 'md:grid-cols-2', 'gap-y-6', 'gap-x-10', 'md:items-center')}
			>
				<div
					className={clsx(
						'flex',
						'items-center',
						'lg:py-10',
						imageAlignment === 'left' ? 'order-1' : 'order-0 max-lg:order-1',
					)}
				>
					<div>
						{heading && (
							<Heading level={2} size="3xlarge" className={clsx('mb-4')}>
								{heading}
							</Heading>
						)}
						{children}
					</div>
				</div>

				{image && (
					<ImageBlock
						src={image.image.src}
						width={image.image.width}
						height={image.image.height}
						alt={image.altText ?? ''}
						className={clsx('rounded-xl')}
					/>
				)}
			</div>
		</Container>
	);
};
