import type {PropsWithChildren} from 'react';
import {groq} from 'next-sanity';

import type {ImageWebSchema} from '@/modules/foundation/sanity/image/schema';
import type {LinkFragment} from '@/modules/foundation/sanity/link/query';
import {LINK_FRAGMENT} from '@/modules/foundation/sanity/link/query';
import type {SanityArray} from '@/sanity/lib/types';

import type {CardDescriptionSchema, FeaturedCardListSchema} from './schema';

export const FEATURED_CARD_LIST_FRAGMENT = groq`
	...,
	cards[]{
		...,
		${LINK_FRAGMENT}
	}
`;

export type CardFragmentType = LinkFragment &
	PropsWithChildren & {
		title?: string;
		tagline?: string;
		icon?: ImageWebSchema;
		description?: CardDescriptionSchema;
	};

export type FeaturedCardListFragment = Omit<FeaturedCardListSchema, 'cards'> & {
	cards?: SanityArray<CardFragmentType>;
};
