import {type FC, Suspense} from 'react';

import {
	Container,
	type ContainerBackgroundColor,
	type ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Article} from '../../shared/types';

import {ArticleListFilter} from './ArticleListAllFilter';
import {ArticleListSimple} from './ArticleListAllSimple';

interface Props {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	limit?: number;
	articles: KeyedArray<Article>;
	backgroundColor?: ContainerBackgroundColor;
	hasFilter?: boolean;
}

const ArticleListPaginated: FC<Props> = ({
	tagline,
	title,
	spacingBottom,
	spacingTop,
	limit,
	articles,
	backgroundColor,
	hasFilter = false,
}) => {
	const limitedArticles = limit && limit > 0 ? articles.slice(0, limit) : articles;

	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}

			{tagline && <Paragraph>{tagline}</Paragraph>}

			{hasFilter ? (
				<Suspense fallback={null}>
					<ArticleListFilter articles={limitedArticles} />
				</Suspense>
			) : (
				<ArticleListSimple articles={limitedArticles} />
			)}
		</Container>
	);
};

export default ArticleListPaginated;
