import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';
import Carousel from 'nuka-carousel';

import {
	Container,
	type ContainerProps,
	type ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Figure} from '@/modules/foundation/components/figure';
import {FullScreenIcon} from '@/modules/foundation/components/icons/full-screen-icon';
import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {usePrefersReducedMotion} from '@/modules/foundation/hooks/usePrefersReducedMotion';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import {renderCenterLeftControls, renderCenterRightControls} from './controls';
import {Lightbox} from './lightbox';

type Props = {
	images: KeyedArray<ImageWeb>;
	width?: ContainerProps['width'];
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerProps['backgroundColor'];
};

const defaultControlsConfig = {
	pagingDotsClassName: 'm-2',
	pagingDotsContainerClassName: '!absolute !top-[100%] -translate-x-1/2',
};

export const Gallery: FC<Props> = ({spacingBottom, spacingTop, images, width, backgroundColor}) => {
	const prefersReducedMotion = usePrefersReducedMotion();

	const [isOpen, setIsOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);

	const hasMultipleImages = images.length > 1;

	const handleAfterSlide = useCallback((slideIndex: number) => {
		setCurrentIndex(slideIndex);
	}, []);

	const handleClick = useCallback(() => {
		setIsOpen(true);
	}, []);

	const renderTopRightControls = useCallback(
		() => <FullscreenButton onClick={handleClick} />,
		[handleClick],
	);

	return (
		<>
			<Container
				width={width}
				spacingBottom={spacingBottom}
				spacingTop={spacingTop}
				backgroundColor={backgroundColor}
			>
				{hasMultipleImages ? (
					<Carousel
						renderCenterLeftControls={renderCenterLeftControls}
						renderCenterRightControls={renderCenterRightControls}
						renderTopRightControls={renderTopRightControls}
						disableAnimation={prefersReducedMotion}
						disableEdgeSwiping={true}
						adaptiveHeight={true}
						defaultControlsConfig={defaultControlsConfig}
						afterSlide={handleAfterSlide}
					>
						{images.map((image) => (
							<CarouselImage key={image._key} image={image} />
						))}
					</Carousel>
				) : (
					<>
						<div className={clsx('absolute', 'right-0', 'top-0')}>
							<FullscreenButton onClick={handleClick} />
						</div>
						<CarouselImage image={images[0]} />
					</>
				)}
			</Container>

			<Lightbox
				setIsOpen={setIsOpen}
				initialIndex={currentIndex}
				isOpen={isOpen}
				images={images}
			/>
		</>
	);
};

type CarouselImageProps = {
	image: ImageWeb;
};

const CarouselImage: FC<CarouselImageProps> = ({image}) => {
	return (
		<div>
			{!image.caption && !image.credit && (
				<ImageBlock
					src={image.image.src}
					width={image.image.width}
					height={image.image.height}
					alt={image.altText ?? ''}
					priority={true}
				/>
			)}

			{(image.caption || image.credit) && (
				<Figure
					credit={image.credit}
					caption={image.caption}
					classNameOverride={{figcaption: 'max-sm:px-5'}}
				>
					<ImageBlock
						src={image.image.src}
						width={image.image.width}
						height={image.image.height}
						alt={image.altText ?? ''}
						priority={true}
					/>
				</Figure>
			)}
		</div>
	);
};

type FullscreenButtonProps = {
	onClick?: () => void;
};

const FullscreenButton: FC<FullscreenButtonProps> = ({onClick}) => {
	return (
		<button
			onClick={onClick}
			className={clsx('opacity-70', 'hover:opacity-100')}
			aria-label="Åpne bildegalleri i fullskjerm"
		>
			<FullScreenIcon size={40} />
		</button>
	);
};
