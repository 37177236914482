import type {FC} from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {formatDate} from '@/modules/foundation/shared/format';

import type {Article} from '../../shared/types';

type Props = {
	article: Article;
	headingLevel: 2 | 3;
};

export const ArticleCard: FC<Props> = ({article, headingLevel}) => {
	const {title, createdAt, href, imageWeb, lead, categories} = article;

	const formattedDate = formatDate(createdAt);

	return (
		<Link
			href={href}
			className={clsx('grid', 'md:grid-cols-2', 'lg:grid-cols-3', 'py-12', 'group')}
		>
			{imageWeb && (
				<ImageBlock
					height={imageWeb.image.height}
					src={imageWeb.image.src}
					width={imageWeb.image.width}
					alt=""
					className={clsx('md:col-span-1', 'self-center', 'max-md:mb-4')}
				/>
			)}
			<div
				className={clsx(
					'md:col-span-1',
					'lg:col-span-2',
					'md:p-8',
					'flex',
					'flex-col',
					'md:justify-center',
					'text-pretty',
				)}
			>
				{categories && categories.length && (
					<ul className={clsx('flex', 'gap-3', 'flex-wrap', 'mb-2')}>
						{categories.map((category) => (
							<li
								key={category._key}
								className={clsx(
									'rounded-full',
									'inline-block',
									'px-3',
									'pt-2',
									'pb-1',
									'border',
									'border-gray-300',
									'leading-none',
									'shrink-0',
								)}
							>
								{category.title}
							</li>
						))}
					</ul>
				)}

				<Heading
					level={headingLevel}
					size="2xlarge"
					spacing
					className="group-hover:underline"
				>
					{title}
				</Heading>

				{lead && <Paragraph>{lead}</Paragraph>}

				<Paragraph asChild>
					<time dateTime={createdAt} className={clsx('text-sm', 'mt-4')}>
						{formattedDate}
					</time>
				</Paragraph>
			</div>
		</Link>
	);
};
