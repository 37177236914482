import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';

import type {ServiceCategoryDetails} from '../../shared/types';
import type {ServiceSchema} from '../service/schema';

import {DOCUMENT_TYPE_SERVICE_CATEGORY} from './constants';
import type {ServiceCategorySchema} from './schema';

export const SERVICE_CATEGORY_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	description,
	"services": *[_type == "service" && references(^._id)] {
		_id,
		title
	}
`;

type ServiceInServiceCategoryCardFragment = Pick<ServiceSchema, 'title' | '_id'>;

export type ServiceCategoryInCardFragment = Pick<
	ServiceCategorySchema,
	'_id' | 'title' | 'slug' | 'description'
> & {
	services?: ServiceInServiceCategoryCardFragment[];
};

export function serviceCategoryInCardFragmentToServiceCategoryDetails(
	serviceCategory: ServiceCategoryInCardFragment,
): ServiceCategoryDetails {
	const services = serviceCategory.services?.map((service) => ({
		title: service.title,
		_key: service._id,
	}));

	return {
		title: serviceCategory.title,
		href: internalLinkToHref(DOCUMENT_TYPE_SERVICE_CATEGORY, serviceCategory.slug?.current),
		description: serviceCategory.description
			? toPlainText(serviceCategory.description)
			: undefined,
		services: services,
	};
}
