'use client';

import type {ChangeEvent} from 'react';
import clsx from 'clsx';

import {FilterChip} from './FilterChip';

export type Category = {
	slug: string;
	title: string;
};

type Props = {
	categories: Category[];
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	filter: string | null;
};

export const Filter = ({categories, onChange, filter}: Props) => {
	return (
		<div
			className={clsx(
				'no-scrollbar',
				'-mx-6',
				'px-6',
				'w-[calc(100%+48px)]',
				'overflow-x-auto',
			)}
		>
			<fieldset className={clsx('flex', 'py-4', 'gap-x-4', 'whitespace-nowrap')}>
				<legend className={clsx('sr-only')}>Velg kategorier</legend>
				<FilterChip
					key="alle"
					id="alle"
					value="alle"
					label="Alle"
					checked={filter === 'alle' || !filter}
					onChange={onChange}
				/>
				{categories.map((category) => (
					<FilterChip
						key={category.slug}
						id={category.slug}
						value={category.slug}
						label={category.title}
						checked={filter === category.slug}
						onChange={onChange}
					/>
				))}
			</fieldset>
		</div>
	);
};

export const filterToSearchParams = (filter: string) => {
	const searchParams = new URLSearchParams();
	searchParams.set('kategori', filter);
	return searchParams;
};

export const searchParamsToFilter = (searchParams: URLSearchParams) => {
	const filter = searchParams.get('kategori');
	return filter;
};
