import type {FC} from 'react';
import clsx from 'clsx';

import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Link} from '@/modules/foundation/components/link';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

type Props = {
	href: string;
	title: string;
	image?: ImageWeb;
	linkText?: string;
	description?: string;
	headingLevel?: 2 | 3;
};

const LinkCard: FC<Props> = ({image, href, title, linkText, description, headingLevel = 2}) => {
	return (
		<div className={clsx('grid', 'gap-4')}>
			<div className={clsx('relative', 'aspect-[4/3]', 'rounded-xl', 'overflow-hidden')}>
				{image && (
					<ImageFill
						src={image.image.src}
						width={image.image.width}
						height={image.image.height}
						hotspot={image.image.hotspot}
						alt={image.altText ?? ''}
					/>
				)}
			</div>
			<Heading
				level={headingLevel}
				size="medium"
				className={clsx('font-semibold', 'text-black')}
			>
				{title}
			</Heading>

			{description && <Paragraph className="mb-2">{description}</Paragraph>}

			<Link href={href}>{linkText}</Link>
		</div>
	);
};

export default LinkCard;
