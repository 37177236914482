'use client';

import React, {createRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import axios, {AxiosError} from 'axios';
import clsx from 'clsx';

import {Button} from '@/modules/foundation/components/button';
import {Spinner} from '@/modules/foundation/components/spinner';

import {FormField, type ValidFieldNames} from '../form-field';
import {ResponseMessage} from '../response-message';

import {FormSchema} from './FormSchema';

// Define the form data structure
export type FormData = {
	name: string;
	email: string;
	phone?: string;
	businessName?: string;
	message?: string;
};

const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const recaptchaRef = createRef<ReCAPTCHA>();

export const Form = () => {
	const {
		register,
		handleSubmit,
		formState: {errors, isSubmitting},
		setError,
		setValue,
	} = useForm<FormData>({
		resolver: zodResolver(FormSchema),
	});

	const [responseMessage, setResponseMessage] = useState<string | null>(null);
	const [hasSubmitError, setHasSubmitError] = useState(false);

	const onSubmit = async (data: FormData) => {
		const recaptchaToken = await recaptchaRef.current?.executeAsync();

		try {
			setResponseMessage(null);
			setHasSubmitError(false);
			const response = await axios.post('/api/contact', data, {
				headers: {
					'Content-Type': 'application/json',
					'g-recaptcha-token': recaptchaToken ?? '',
					Accept: 'application/json',
				},
			});

			const {errors = {}} = response.data; // Destructure the 'errors' property from the response data

			// Define a mapping between server-side field names and their corresponding client-side names
			const fieldErrorMapping: Record<string, ValidFieldNames> = {
				name: 'name',
				email: 'email',
				phone: 'phone',
				businessName: 'businessName',
				message: 'message',
			};

			// Find the first field with an error in the response data
			const fieldWithError = Object.keys(fieldErrorMapping).find((field) => errors[field]);

			// If a field with an error is found, update the form error state using setError
			if (fieldWithError) {
				// Use the ValidFieldNames type to ensure the correct field names
				setError(fieldErrorMapping[fieldWithError], {
					type: 'server',
					message: errors[fieldWithError],
				});
			}

			// If the response status is 200 and there are no field errors, clear the form values
			if (response.status === 200 && !fieldWithError) {
				setValue('name', '');
				setValue('email', '');
				setValue('message', '');
				setValue('phone', '');
				setValue('businessName', '');
				setResponseMessage('Epost er ikke satt opp enda, dette er en test');
				setHasSubmitError(false);
			}
		} catch (error) {
			setResponseMessage('Noe gikk galt, prøv igjen senere');
			setHasSubmitError(true);
			if (error instanceof AxiosError) {
				console.error(error.response?.data.message);
			} else {
				console.error(error);
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={clsx('w-full', 'max-w-[550px]', 'mx-auto')}
		>
			<div className={clsx('col-auto', 'grid', 'gap-6')}>
				<div className={clsx('grid', 'md:grid-cols-2', 'gap-6', 'items-start')}>
					<FormField
						type="text"
						name="name"
						label="Ditt navn"
						register={register}
						error={errors.name}
						required
					/>

					<FormField
						type="email"
						name="email"
						label="E-post"
						register={register}
						error={errors.email}
						required
					/>
				</div>

				<div className={clsx('grid', 'md:grid-cols-2', 'gap-6')}>
					<FormField
						type="tel"
						name="phone"
						label="Telefon"
						register={register}
						error={errors.phone}
					/>

					<FormField
						type="text"
						name="businessName"
						label="Bedriftsnavn"
						register={register}
						error={errors.businessName}
					/>
				</div>

				<FormField
					type="textarea"
					name="message"
					label="Hva kan vi hjelpe deg med?"
					register={register}
					error={errors.message}
				/>

				{responseMessage && (
					<ResponseMessage success={!hasSubmitError} message={responseMessage} />
				)}

				<ResponseMessage success={false} message="E-post er ikke satt opp enda!" />

				<Button
					type="submit"
					disabled={isSubmitting}
					className={clsx('md:justify-self-start')}
				>
					Send meldingen
					{isSubmitting && <Spinner title="sender" role="status" />}
				</Button>
			</div>
			{siteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />}
		</form>
	);
};
