import type {FC} from 'react';
import clsx from 'clsx';

import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

interface Props {
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	articles: KeyedArray<Article>;
	backgroundColor?: ContainerBackgroundColor;
}

const ArticleList: FC<Props> = ({
	title,
	tagline,
	spacingBottom,
	spacingTop,
	articles,
	backgroundColor,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			<ul className={clsx('grid', 'auto-rows-fr', 'grid-cols-1', 'lg:grid-cols-3')}>
				{articles && (
					<>
						{articles.map((article) => {
							return (
								<li key={article._key}>
									<ArticleCard article={article} headingLevel={title ? 3 : 2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
		</Container>
	);
};
export default ArticleList;
