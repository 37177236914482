import type {FC} from 'react';

import {ServiceCategoryList} from '../../components/service-category-list';

import type {ServiceCategoryListAllFragment} from './query';
import {serviceCategoryListFromServiceCategoryListAllSection} from './query';

type Props = {
	data: ServiceCategoryListAllFragment;
};

export const ServiceCategoryListAllContainer: FC<Props> = ({data}) => {
	const serviceCategories = serviceCategoryListFromServiceCategoryListAllSection(data);

	return (
		<ServiceCategoryList
			serviceCategories={serviceCategories}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			tagline={data.tagline}
		/>
	);
};
