'use client';

import type {ChangeEvent} from 'react';
import {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';

import {Button} from '@/modules/foundation/components/button';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

import type {Category} from './filter';
import {Filter, filterToSearchParams, searchParamsToFilter} from './filter';

interface Props {
	articles: KeyedArray<Article>;
}

const VISIBLE_ARTICLES_LIMIT = 6;

export const ArticleListFilter: FC<Props> = ({articles}) => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();

	const filter = searchParamsToFilter(searchParams);

	const filteredArticles = useMemo(() => {
		if (!filter || filter === 'alle') {
			return articles;
		}

		return articles.filter((article) => {
			if (!article.categories) {
				return false;
			}

			return article.categories.some((category) => category.slug === filter);
		});
	}, [articles, filter]);

	const [visibleArticles, setVisibleArticles] = useState<KeyedArray<Article>>(
		filteredArticles.slice(0, VISIBLE_ARTICLES_LIMIT),
	);

	// Reset visible articles when filter changes
	useEffect(() => {
		setVisibleArticles(filteredArticles.slice(0, VISIBLE_ARTICLES_LIMIT));
	}, [filteredArticles]);

	// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
	const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value !== filter) {
			const searchParams = filterToSearchParams(event.target.value);
			router.push(`${pathname}?${searchParams.toString()}`, {scroll: false});
		}
	};

	const loadMore = useCallback(() => {
		const nextVisibleArticles = filteredArticles.slice(
			0,
			visibleArticles?.length + VISIBLE_ARTICLES_LIMIT,
		);
		setVisibleArticles(nextVisibleArticles);
	}, [filteredArticles, visibleArticles]);

	if (!visibleArticles) return null;

	const totalArticles = filteredArticles?.length;

	const hasMore = visibleArticles.length < totalArticles;

	const categories: Category[] = articles.reduce((acc: Category[], article) => {
		if (!article.categories) {
			return acc;
		}

		article.categories.forEach((category) => {
			if (!acc.find((c) => c.slug === category.slug)) {
				acc.push({
					slug: category.slug ?? '',
					title: category.title ?? '',
				});
			}
		});

		return acc;
	}, []);

	return (
		<>
			{categories && categories.length ? (
				<Filter categories={categories} onChange={handleFilterChange} filter={filter} />
			) : null}
			<ul className={clsx('grid', 'grid-cols-1')}>
				{visibleArticles && visibleArticles.length && (
					<>
						{visibleArticles.map((article) => {
							if (!article) {
								return null;
							}

							return (
								<li
									key={article._key}
									className={clsx(
										'border-b',
										'border-gray-300',
										'last:border-none',
									)}
								>
									<ArticleCard article={article} headingLevel={2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
			{hasMore && (
				<Button onClick={loadMore} className={clsx('justify-self-center', 'mt-4')}>
					Vis flere artikler
				</Button>
			)}
		</>
	);
};
