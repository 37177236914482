import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceCategoryDetails} from '../../shared/types';
import {DOCUMENT_TYPE_SERVICE_CATEGORY} from '../service-category/constants';
import type {ServiceCategoryInCardFragment} from '../service-category/query';
import {
	SERVICE_CATEGORY_IN_CARD_FRAGMENT,
	serviceCategoryInCardFragmentToServiceCategoryDetails,
} from '../service-category/query';

import type {BLOCK_TYPE_SERVICE_CATEGORY_LIST_ALL} from './constants';
import type {ServiceCategoryListAllSchema} from './schema';

export const SERVICE_CATEGORY_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"serviceCategories": *[_type == "${DOCUMENT_TYPE_SERVICE_CATEGORY}"] | order(_createdAt desc) {
			${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"serviceCategories": *[_type == "${DOCUMENT_TYPE_SERVICE_CATEGORY}"] | order(_createdAt asc) {
			${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"serviceCategories": *[_type == "${DOCUMENT_TYPE_SERVICE_CATEGORY}"] | order(lower(title) asc) {
			${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"serviceCategories": *[_type == "${DOCUMENT_TYPE_SERVICE_CATEGORY}"] | order(lower(title) desc) {
			${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"serviceCategories": *[_type == "${DOCUMENT_TYPE_SERVICE_CATEGORY}"] | order(_createdAt desc) {
			${SERVICE_CATEGORY_IN_CARD_FRAGMENT}
		}
	}
`;

export type ServiceCategoryListAllFragment = Omit<
	ServiceCategoryListAllSchema,
	'serviceCategories'
> & {
	_type: typeof BLOCK_TYPE_SERVICE_CATEGORY_LIST_ALL;
	serviceCategories?: (ServiceCategoryInCardFragment | null)[];
};

export const serviceCategoryListFromServiceCategoryListAllSection = (
	section: ServiceCategoryListAllFragment,
): KeyedArray<ServiceCategoryDetails> => {
	const serviceCategories: KeyedArray<ServiceCategoryDetails> = [];

	if (!section.serviceCategories) {
		return serviceCategories;
	}

	for (const serviceCategoryInCard of section.serviceCategories) {
		if (serviceCategoryInCard) {
			const serviceCategory =
				serviceCategoryInCardFragmentToServiceCategoryDetails(serviceCategoryInCard);
			if (serviceCategory) {
				serviceCategories.push({
					_key: serviceCategoryInCard._id,
					...serviceCategory,
				});
			}
		}
	}

	return serviceCategories;
};
