import type {FC, PropsWithChildren} from 'react';

import {type ImageWeb} from '@/modules/foundation/components/image/types';
import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {linkFragmentToHref} from '@/modules/foundation/sanity/link/query';
import type {KeyedArray} from '@/modules/foundation/shared/types';
import {LinkListSelection} from '@/modules/page/components/content-blocks/link-list-selection';
import type {LinkInCard} from '@/modules/page/components/content-blocks/link-list-selection/LinkCardGrid';

import type {LinkListSelectionFragmentType} from './query';

type Props = PropsWithChildren & {
	data: LinkListSelectionFragmentType;
};

export const LinkListSelectionContainer: FC<Props> = ({data}) => {
	const links: KeyedArray<LinkInCard> = [];

	if (data.links) {
		for (const link of data.links) {
			let image: ImageWeb | undefined;
			if (link.image?.asset?._ref) {
				image = imageWebFromImageWebSchema(link.image);
			}

			links.push({
				_key: link._key,
				href: linkFragmentToHref(link),
				title: link.title ?? 'Mangler tittel...',
				image: image,
				imageAltText: link.image?.altText,
				description: link.description,
				linkText: link.linkText ?? '',
			});
		}
	}

	return (
		<LinkListSelection
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			tagline={data.tagline}
			links={links}
			backgroundColor={data.backgroundColor}
		/>
	);
};
