'use client';

import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';

import {Button} from '@/modules/foundation/components/button';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

interface Props {
	articles: KeyedArray<Article>;
}

const VISIBLE_ARTICLES_LIMIT = 6;

export const ArticleListSimple: FC<Props> = ({articles}) => {
	const [visibleArticles, setVisibleArticles] = useState<KeyedArray<Article>>(
		articles.slice(0, VISIBLE_ARTICLES_LIMIT),
	);

	const loadMore = useCallback(() => {
		const nextVisibleArticles = articles.slice(
			0,
			visibleArticles?.length + VISIBLE_ARTICLES_LIMIT,
		);
		setVisibleArticles(nextVisibleArticles);
	}, [articles, visibleArticles]);

	if (!visibleArticles) return null;

	const totalArticles = articles?.length;

	const hasMore = visibleArticles.length < totalArticles;

	return (
		<>
			<ul className={clsx('grid', 'grid-cols-1')}>
				{visibleArticles && visibleArticles.length && (
					<>
						{visibleArticles.map((article) => {
							if (!article) {
								return null;
							}

							return (
								<li
									key={article._key}
									className={clsx(
										'border-b',
										'border-gray-300',
										'last:border-none',
									)}
								>
									<ArticleCard article={article} headingLevel={2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
			{hasMore && (
				<Button onClick={loadMore} className={clsx('justify-self-center', 'mt-4')}>
					Vis flere artikler
				</Button>
			)}
		</>
	);
};
