import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerCaseListSelectionSchema} from '../../sanity/customer-case-list-selection-container/schema';
import type {CustomerCaseDetails} from '../../shared/types';
import type {CustomerCaseInCardFragment} from '../customer-case/query';
import {
	CUSTOMER_CASE_IN_CARD_FRAGMENT,
	customerCaseInCardFragmentToCustomerCaseDetails,
} from '../customer-case/query';

import type {BLOCK_TYPE_CUSTOMER_CASE_LIST_SELECTION} from './constants';

export const CUSTOMER_CASE_LIST_SELECTION_FRAGMENT = groq`
	...,
	customerCases[]-> {
		${CUSTOMER_CASE_IN_CARD_FRAGMENT},
	}
`;

export type CustomerCaseListSelectionFragment = Omit<
	CustomerCaseListSelectionSchema,
	'customerCases'
> & {
	_type: typeof BLOCK_TYPE_CUSTOMER_CASE_LIST_SELECTION;
	customerCases?: (CustomerCaseInCardFragment | null)[];
};

export const customerCaseListFromCustomerCaseListSelectionSection = (
	section: CustomerCaseListSelectionFragment,
): KeyedArray<CustomerCaseDetails> => {
	const customerCases: KeyedArray<CustomerCaseDetails> = [];

	if (!section.customerCases) {
		return customerCases;
	}

	for (const customerCaseInCard of section.customerCases) {
		if (customerCaseInCard) {
			const customerCase =
				customerCaseInCardFragmentToCustomerCaseDetails(customerCaseInCard);
			if (customerCase) {
				customerCases.push({
					_key: customerCaseInCard._id,
					...customerCase,
				});
			}
		}
	}

	return customerCases;
};
