import type {FC} from 'react';
import {FiArrowRight} from 'react-icons/fi';
import clsx from 'clsx';
import Link from 'next/link';

import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {ServiceDetails} from '../../shared/types';

type Props = {
	title?: string;
	description?: string;
	href?: string;
	services?: KeyedArray<ServiceDetails>;
};

export const ServiceCategoryCard: FC<Props> = ({title, description, href, services}) => {
	console.log(description);
	return (
		<Link
			href={href ?? '#'}
			className={clsx(
				'inline-flex',
				'flex-col',
				'gap-3',
				'group',
				'bg-primary-light',
				'size-full',
				'rounded-xl',
				'p-4',
			)}
		>
			{title && (
				<Paragraph size="xlarge" asChild className={clsx('group-hover:underline')}>
					<span>{title}</span>
				</Paragraph>
			)}

			{description && (
				<Paragraph asChild size="small">
					<span>{description}</span>
				</Paragraph>
			)}

			{services && (
				<ul>
					{services.map((service) => (
						<li key={service._key}>
							<FiArrowRight className={clsx('inline', 'mr-2')} />
							<span>{service.title}</span>
						</li>
					))}
				</ul>
			)}
		</Link>
	);
};
