import type {FC} from 'react';
import React from 'react';
import type {FieldError, UseFormRegister} from 'react-hook-form';
import {FiAlertCircle} from 'react-icons/fi';
import clsx from 'clsx';

import type {FormData} from '../form';

type FormFieldProps = {
	type: string;
	placeholder?: string;
	name: ValidFieldNames;
	label: string;
	register?: UseFormRegister<FormData>;
	error?: FieldError;
	valueAsNumber?: boolean;
	required?: boolean;
};

export type ValidFieldNames = 'name' | 'email' | 'message' | 'phone' | 'businessName';

export const FormField: FC<FormFieldProps> = ({
	type,
	placeholder,
	name,
	label,
	register,
	error,
	valueAsNumber,
	required,
}) => {
	if (type === 'textarea') {
		return (
			<div className={clsx('grid', 'gap-1')}>
				<label htmlFor={name} className={clsx('font-bold', 'text-primary')}>
					{label}
					{!required && (
						<span className={clsx('text-gray-500', 'font-normal')}> (valgfritt)</span>
					)}
				</label>
				<textarea
					id={name}
					placeholder={placeholder}
					{...(required && {'aria-required': true})}
					{...(error && {'aria-invalid': true})}
					{...(register && register(name, {valueAsNumber}))}
					className={clsx(
						'focus-visible:border-primary',
						'bg-white',
						'border',
						'border-black/80',
						'rounded',
						'min-h-[112px]',
						error && 'border-red-600',
					)}
				/>
				{error && (
					<span role="alert" className={clsx('flex', 'gap-1', 'mt-1')}>
						<FiAlertCircle aria-hidden className="mt-[2px] text-red-600" />
						{error.message}
					</span>
				)}
			</div>
		);
	}
	return (
		<div className={clsx('grid', 'gap-1')}>
			<label htmlFor={name} className={clsx('font-bold', 'text-primary')}>
				{label}
				{!required && (
					<span className={clsx('text-gray-500', 'font-normal')}> (valgfritt)</span>
				)}
			</label>
			<input
				id={name}
				type={type}
				placeholder={placeholder}
				{...(required && {'aria-required': true})}
				{...(error && {'aria-invalid': true})}
				{...(register && register(name, {valueAsNumber}))}
				className={clsx(
					'focus-visible:border-primary',
					'bg-white',
					'border',
					'border-black/80',
					'rounded',
					error && 'border-red-600',
				)}
			/>
			{error && (
				<span role="alert" className={clsx('flex', 'gap-1', 'mt-1')}>
					<FiAlertCircle aria-hidden className="mt-[2px] text-red-600" />
					{error.message}
				</span>
			)}
		</div>
	);
};
