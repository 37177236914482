import type {FC} from 'react';

import {CustomerCaseList} from '../../components/customer-case-list';

import {
	type CustomerCaseListAllFragment,
	customerCaseListFromCustomerCaseListAllSection,
} from './query';

type Props = {
	data: CustomerCaseListAllFragment;
};

export const CustomerCaseListAllContainer: FC<Props> = ({data}) => {
	const customerCases = customerCaseListFromCustomerCaseListAllSection(data);

	return (
		<CustomerCaseList
			customerCases={customerCases}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
