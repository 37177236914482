import type {FC, PropsWithChildren} from 'react';

import {
	Container,
	type ContainerBackgroundColor,
	type ContainerSpacing,
} from '@/modules/foundation/components/container';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerBackgroundColor;
};

export const textPortableTextOverrides: PortableTextOverrides = {};

export const Text: FC<Props> = ({children, spacingBottom, spacingTop, backgroundColor}) => {
	return (
		<Container
			width="text"
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{children}
		</Container>
	);
};
