import type {FC} from 'react';
import clsx from 'clsx';

import type {
	ContainerBackgroundColor,
	ContainerSpacing,
} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import LinkCard from './LinkCard';

export interface LinkInCard {
	href: string;
	title: string;
	image?: ImageWeb;
	imageAltText?: string;
	linkText: string;
	description?: string;
}

type Props = {
	heading?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	links?: KeyedArray<LinkInCard>;
	backgroundColor?: ContainerBackgroundColor;
};

const LinkCardGrid: FC<Props> = ({
	heading,
	tagline,
	spacingBottom,
	spacingTop,
	links,
	backgroundColor,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{heading && (
				<Heading level={2} spacing>
					{heading}
				</Heading>
			)}
			{tagline && <Paragraph className="mb-8">{tagline}</Paragraph>}

			<ul
				className={clsx(
					'grid',
					'gap-10',
					'grid-cols-1',
					'md:grid-cols-3',
					'justify-start',
					'content-start',
				)}
			>
				{links?.map((link) => (
					<li key={link._key}>
						<LinkCard
							title={link.title}
							href={link.href}
							image={link.image}
							description={link.description}
							linkText={link.linkText}
							headingLevel={heading ? 3 : 2}
						/>
					</li>
				))}
			</ul>
		</Container>
	);
};

export default LinkCardGrid;
