import clsx from 'clsx';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

type Props = {
	name?: string;
	imageWeb?: ImageWeb;
	quote?: string;
	role?: string;
	customer?: string;
};

export const CustomerTestimonialCard = ({name, imageWeb, quote, role, customer}: Props) => {
	return (
		<div className={clsx('flex', 'flex-col', 'items-center', 'text-center', 'gap-8')}>
			{imageWeb && (
				<div className={clsx('w-full', 'max-w-[100px]', 'md:max-w-[140px]', 'shrink-0')}>
					<ImageBlock
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						height={imageWeb.image.height}
						alt={imageWeb.altText ?? ''}
						className={clsx('rounded-full', 'border-[3px]', 'border-primary')}
					/>
				</div>
			)}
			<Paragraph
				asChild
				size="xlarge"
				className={clsx('text-lg', 'text-primary', 'text-balance')}
			>
				<blockquote>
					<span className="text-secondary">&laquo;</span>
					{quote}
					<span className="text-secondary">&raquo;</span>
				</blockquote>
			</Paragraph>
			<div>
				{name && (
					<Paragraph
						asChild
						size="large"
						className={clsx('text-primary', 'block', 'font-bold')}
					>
						<span>{name}</span>
					</Paragraph>
				)}
				{role && (
					<Paragraph asChild className={clsx('text-primary-medium')}>
						<span>{role}</span>
					</Paragraph>
				)}
				{role && customer && <span className={clsx('text-primary-medium')}> - </span>}
				{customer && (
					<Paragraph asChild className={clsx('text-primary-medium')}>
						<span>{customer}</span>
					</Paragraph>
				)}
			</div>
		</div>
	);
};
