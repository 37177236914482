import type {FC, PropsWithChildren} from 'react';
import {FiArrowRight} from 'react-icons/fi';
import clsx from 'clsx';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Link} from '@/modules/foundation/components/link';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

export type FeaturedCardProps = PropsWithChildren & {
	imageWeb?: ImageWeb;
	title?: string;
	headingLevel?: 2 | 3;
	tagline?: string;
	href?: string;
	targetBlank?: boolean;
};

export const featuredCardPortableTextOverrides: PortableTextOverrides = {};

export const FeaturedCard: FC<FeaturedCardProps> = (props) => {
	return (
		<>
			{props.href && props.href !== '#' ? (
				<Link
					targetBlank={props.targetBlank}
					href={props.href}
					className={clsx(
						'no-underline',
						'group',
						'hover:shadow-[0px_0px_0px_3px_#695BD4]',
						'focus-visible:!shadow-[0px_0px_0px_3px_#695BD4]',
						'inline-block',
						'rounded-xl',
						'transition-shadow',
						'h-full',
					)}
				>
					<Card {...props} />
				</Link>
			) : (
				<Card {...props} />
			)}
		</>
	);
};

const Card: FC<FeaturedCardProps> = ({
	imageWeb,
	title,
	headingLevel = 2,
	tagline,
	children,
	href,
}) => {
	return (
		<div
			className={clsx(
				'text-center',
				'flex',
				'flex-col',
				'justify-center',
				'items-center',
				'bg-white',
				'rounded-xl',
				'p-8',
				'gap-4',
				'h-full',
			)}
		>
			{imageWeb?.image && (
				<div className={clsx('size-12')}>
					<ImageBlock
						height={imageWeb.image.height}
						width={imageWeb.image.width}
						src={imageWeb.image.src}
						alt=""
					/>
				</div>
			)}

			<div>
				{title && (
					<Heading level={headingLevel} size="xlarge" className={clsx('font-medium')}>
						{title}
					</Heading>
				)}

				{tagline && (
					<Paragraph className={clsx('text-primary-medium')}>{tagline}</Paragraph>
				)}
			</div>

			{children && <div className="text-black">{children}</div>}

			{href && href !== '#' && (
				<FiArrowRight
					aria-hidden
					className={clsx(
						'text-xl',
						'text-secondary',
						'group-hover:translate-x-3',
						'transition-transform',
					)}
				/>
			)}
		</div>
	);
};
