import type {FC} from 'react';

import {ContactFormSection} from '../../components/contact-form-section';

import type {SanityContactFormSectionSchema} from './contact-form-section';

type Props = {
	data: SanityContactFormSectionSchema;
};

export const ContactFormSectionContainer: FC<Props> = ({data}) => {
	return (
		<ContactFormSection
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			backgroundColor={data.backgroundColor}
			heading={data.heading}
			tagline={data.tagline}
		/>
	);
};
