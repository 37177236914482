import React from 'react';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';

import {cn} from '@/modules/foundation/shared/tailwind';

const responseMessage = cva(['border', 'py-6', 'px-8', 'rounded-lg'], {
	variants: {
		success: {
			true: ['text-green-700', 'bg-green-50', 'border-green-600'],
			false: ['text-red-700', 'bg-red-50', 'border-red-600'],
		},
	},
});

type ResponseMessageProps = VariantProps<typeof responseMessage> & {
	message: string;
};

export const ResponseMessage = ({success, message}: ResponseMessageProps) => {
	return (
		<div className={cn(responseMessage({success}))} role="status">
			{message}
		</div>
	);
};
