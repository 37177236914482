import type {FC} from 'react';

import {PortableText} from '@/modules/foundation/sanity/portable-text';
import {Text, textPortableTextOverrides} from '@/modules/page/components/content-blocks/text';

import type {TextSectionFragmentType} from './query';

interface Props {
	data: TextSectionFragmentType;
}

export const TextContainer: FC<Props> = ({data}) => {
	const {content, layout} = data ?? {};

	return (
		<Text
			spacingBottom={layout?.spacingBottom}
			spacingTop={layout?.spacingTop}
			backgroundColor={data.backgroundColor}
		>
			<div>
				{content && (
					<PortableText blocks={content} overrideClassNames={textPortableTextOverrides} />
				)}
			</div>
		</Text>
	);
};
