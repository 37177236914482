import type {FC} from 'react';

import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {PortableText} from '@/modules/foundation/sanity/portable-text';
import {
	TextWithImageSplit,
	textWithImageSplitPortableTextOverrides,
} from '@/modules/page/components/content-blocks/text-with-image-split';

import type {TextWithImageSplitFragment} from './query';

interface Props {
	data: TextWithImageSplitFragment;
}

export const TextWithImageSplitContainer: FC<Props> = ({data}) => {
	let image: ImageWeb | undefined;

	if (data.image?.asset?._ref) {
		image = imageWebFromImageWebSchema(data.image);
	}

	return (
		<TextWithImageSplit
			imageAlignment={data.alignment}
			image={image}
			spacingTop={data.layout?.spacingTop}
			spacingBottom={data.layout?.spacingBottom}
			heading={data.title}
			backgroundColor={data.backgroundColor}
		>
			{data.content && (
				<PortableText
					blocks={data.content}
					overrideClassNames={textWithImageSplitPortableTextOverrides}
				/>
			)}
		</TextWithImageSplit>
	);
};
