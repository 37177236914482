import type {FC} from 'react';
import {FiArrowRight} from 'react-icons/fi';
import {LuImageOff} from 'react-icons/lu';
import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

type Props = {
	heading?: string;
	imageWeb?: ImageWeb;
	introduction?: string;
	href?: string;
	customer?: string;
};

export const CustomerCaseCard: FC<Props> = ({heading, imageWeb, introduction, href, customer}) => {
	return (
		<Link href={href ?? '#'} className={clsx('grid', 'gap-2', 'group')}>
			{imageWeb ? (
				<div className={clsx('overflow-hidden', 'aspect-video', 'rounded-xl', 'mb-2')}>
					<ImageBlock
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						height={imageWeb.image.height}
						alt={imageWeb.altText ?? ''}
						className={clsx(
							'rounded-xl',
							'group-hover:scale-110',
							'transition-transform',
						)}
					/>
				</div>
			) : (
				<div
					className={clsx(
						'aspect-video',
						'mb-2',
						'bg-primary-light',
						'rounded-xl',
						'grid',
						'place-content-center',
					)}
				>
					<LuImageOff
						aria-hidden
						className={clsx(
							'text-primary-medium',
							'text-4xl',
							'group-hover:scale-110',
							'transition-transform',
						)}
					/>
				</div>
			)}

			{customer && (
				<Paragraph asChild size="small" className={clsx('text-primary-medium')}>
					<span>{customer}</span>
				</Paragraph>
			)}

			{heading && (
				<Paragraph size="xlarge" asChild className={clsx('group-hover:underline')}>
					<span>{heading}</span>
				</Paragraph>
			)}

			{introduction && (
				<Paragraph asChild size="small">
					<span>{introduction}</span>
				</Paragraph>
			)}

			<Paragraph asChild size="small" className={clsx('text-primary-medium')}>
				<span className={clsx('flex', 'items-center', 'gap-1', 'group-hover:underline')}>
					Les kundecasen <FiArrowRight aria-hidden />
				</span>
			</Paragraph>
		</Link>
	);
};
