import type {FC} from 'react';

import {CustomerTestimonialList} from '../../components/customer-testimonial-list';

import type {CustomerTestimonialListSelectionFragment} from './query';
import {customerTestimonialListFromCustomerTestimonialListSelectionSection} from './query';

type Props = {
	data: CustomerTestimonialListSelectionFragment;
};

export const CustomerTestimonialListSelectionContainer: FC<Props> = ({data}) => {
	const customerTestimonials =
		customerTestimonialListFromCustomerTestimonialListSelectionSection(data);

	return (
		<CustomerTestimonialList
			customerTestimonials={customerTestimonials}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			tagline={data.tagline}
		/>
	);
};
