import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {CustomerTestimonialDetails} from '../../shared/types';
import type {CustomerTestimonialInCardFragment} from '../customer-testimonial/query';
import {
	CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT,
	customerTestimonialInCardFragmentToCustomerTestimonialDetails,
} from '../customer-testimonial/query';

import type {BLOCK_TYPE_CUSTOMER_TESTIMONIAL_LIST_SELECTION} from './constants';
import type {CustomerTestimonialListSelectionSchema} from './schema';

export const CUSTOMER_TESTOIMONIAL_LIST_SELECTION_FRAGMENT = groq`
	...,
	testimonials[]-> {
		${CUSTOMER_TESTIMONIAL_IN_CARD_FRAGMENT}
	}
`;

export type CustomerTestimonialListSelectionFragment = Omit<
	CustomerTestimonialListSelectionSchema,
	'testimonials'
> & {
	_type: typeof BLOCK_TYPE_CUSTOMER_TESTIMONIAL_LIST_SELECTION;
	testimonials?: (CustomerTestimonialInCardFragment | null)[];
};

export const customerTestimonialListFromCustomerTestimonialListSelectionSection = (
	section: CustomerTestimonialListSelectionFragment,
): KeyedArray<CustomerTestimonialDetails> => {
	const testimonials: KeyedArray<CustomerTestimonialDetails> = [];

	if (!section.testimonials) {
		return testimonials;
	}

	for (const testimonial of section.testimonials) {
		if (testimonial) {
			const customerTestimonial =
				customerTestimonialInCardFragmentToCustomerTestimonialDetails(testimonial);
			if (customerTestimonial) {
				testimonials.push({
					_key: testimonial._id,
					...customerTestimonial,
				});
			}
		}
	}

	return testimonials;
};
