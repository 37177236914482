import type {FC} from 'react';

import PersonGroupList from '../../components/person-group-list';

import {personGroupsFromPersonListGroupSection, type PersonListGroupFragment} from './query';

type Props = {
	data: PersonListGroupFragment;
};

export const PersonListGroupContainer: FC<Props> = ({data}) => {
	return (
		<PersonGroupList
			groups={personGroupsFromPersonListGroupSection(data)}
			title={data.title}
			tagline={data.tagline}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			backgroundColor={data.backgroundColor}
		/>
	);
};
