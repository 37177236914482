import {groq} from 'next-sanity';

import type {ServiceSchema} from '../service/schema';

import type {ServiceCardSchema} from './schema';

export const SERVICE_CARD_FRAGMENT = groq`
	service->{
		title,
		description
	}
`;

type Service = Pick<ServiceSchema, 'title' | 'description'>;

export type ServiceCardFragment = Omit<ServiceCardSchema, 'service'> & {
	service?: Service;
};
